.h1-custom {
    color: #C4C4C4 !important;
   font-size: 22px;
  }

  .custom-featured-imageGf{
    height:auto;

  }
  
  .f-bange-custom{font-size: 12px;}
  .color-custom-date{color: #C4C4C4;}
  .custom-featured-image{min-width: 100%;border-radius: 8px !important;}
  .custom-margin-col{ margin-left: -15px;margin-right: -15px;}
  .bg-card-custom{background-color: #F5F5F5 !important;border-radius: 8px;}
  .vertical-align-custom{display: flex; align-items: center;}
  
  .text-justify{text-align: justify;}
  
   @media (max-width: 375px) { 
      .custom-margin-col{ margin-left: 0px;margin-right: 0px;}
  }