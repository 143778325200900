#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 18px Helvetica, Arial, sans-serif;
}
#mc_embed_signup .button {
  background-color: #000000 !important;
  font-size: 18px !important;
  line-height: 32px !important;
  height: 45px !important;
  border-radius: 16px !important;
  width: 95% !important;
  margin-top: 5px !important;
}
.email {
  border-radius: 10px !important;
  padding: 10px !important;
}
.text-center-btn {
  text-align: center !important;
}
