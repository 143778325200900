
.giftcardPortada{
  max-height: 420px;
}
.custom-featured-imageGf{
  height:auto;

}
.f-bange-custom {
  font-size: 12px;
}
.vertical-align-custom {
  display: flex;
  align-items: center;
}
.fs-custom-author {
  font-size: 16px;
  font-weight: bold;
}
.fs-custom-author + .speciality {
  color: #616161 !important;
  font-size: 14px;
  font-weight: bold;
}
.vertical-align-custom2 {
  display: flex;
  align-items: center;
}

.h3-fs-custom {
  font-size: 24px;
}
.custom-featured-image{height: 100%;}
.date-h3-fs-custom {
  font-size: 12px;
}

.fs-h3-custom-author {
  font-size: 13px;
}

@media (max-width: 375px) {
  .custom-margin-col {
    margin-left: 0px;
    margin-right: 0px;
  }
}
a {
  color: unset !important;
}
