* {
    font-family: 'Roboto', sans-serif;
}

.mastercard-img {
    position: absolute;
    width: 25%;
    top: 75%;
    right: 25%;
}

.container-banner-home {
    background-image: url('../../assets/img/home/Fondociudad.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 1000px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-banner-bg-home {
    height: 1000px;
    margin-left: 0;
    margin-right: 0; 
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.container-title-home {
    margin: auto;
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid #000000;
    border-radius: 50px;
    height: 30rem;
    width: 90rem;
    padding: 3rem;
    margin-left: 18rem;
}

.h1-title-home {
    color: #FFFFFF;
    font-size: 6rem;
    margin-right: 12rem;
}

.p-subtitle-home {
    color: #FFFFFF;
    font-size: 2.5rem;
    margin-right: 24rem;
}

.img-celular-home {
    margin-left: -3rem;
}

.container-creditos-home {
    margin-top: 9rem;
    margin-bottom: 9rem;
}

.img-circle-woman-home {
    height: 40rem;
}

.h1-creditos-home {
    font-size: 4rem;
    text-align: center;
}

.container-box-creditos {
    display: flex;
}

.box-creditos {
    margin: 1rem;
    background: #000000;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50px;
}

.title-box-creditos {
    color: #FFFFFF;
    padding-right: 3rem;
    padding-left: 3rem;
}

.p-box-credito {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: normal;
    padding-left: 3rem;
    padding-right: 3rem;
    width: 21rem;
}

.btn-box-creditos {
    display: table;
    font-weight: bold;
    margin: 0 auto;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 50px;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 2rem;
    transition: ease 0.3s;
}

.btn-box-creditos:hover {
    background: #F39220;
    color: #FFFFFF;
}

.btn-info-white {
    font-weight: bold;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 50px;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 2rem;
    transition: ease 0.3s;
}

.btn-info-white:hover {
    background: #F39220;
    color: #FFFFFF;
}

.img-bitcoin-home {
    height: 2rem;
    margin-bottom: 1rem;
}

.col-box-creditos {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.row-ditowallet-home {
    background-color: #000000;
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.img-celular-ditowallet {
    margin-left: 0rem;
    height: 56rem;
}

.container-ditowallet-home {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.h1-ditowallet-home {
    color: #FFFFFF;
    font-size: 5rem;
    text-align: center;
}

.img-bitcoinWallet-home {
    height: 4rem;
    margin-bottom: 1rem;
}

.h2-ditowallet-home {
    color: #FFFFFF;
    font-size: 2.6rem;

    font-weight: 200;
    margin-right: 5rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.img-stores-mobile {
    margin: 1rem;
}

.container-tarjetaprepago-row {
    background-image: url('../../assets/img/home/Fondomundobitcoin.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 1000px;
    margin-left: 0;
    margin-right: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-tarjetaprepago-home {
    height: 1000px;
    margin-left: 0;
    margin-right: 0;
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.container-tarjetaprepago-home {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid #000000;
    border-radius: 50px;
    height: 30rem;
    width: 90rem;
    padding: 3rem;
    margin-left: -22rem;
}

.img-tarjetasprepago-home {
    margin-right: -3rem;
    margin-left: 9rem;
    width: 95%;
    height: auto;
}

.h1-tarjetaprepado-home {
    color: #FFFFFF;
    font-size: 5rem;
    text-align: right;
    margin-bottom: 2rem;
}

.img-bitcoinprepago-home {
    height: 4rem;
    margin-bottom: 1rem;
}

.p-tarjetaprepado-home {
    color: #FFFFFF;
    font-size: 2.9rem;
    font-weight: 300;
    margin-left: 25rem;
    margin-bottom: 2rem;
    line-height: normal;
}

.div-btn-tarjetaprepago {
    margin-top: 3rem;
    margin-left: 18rem;
}

.container-giftcard-home {
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.col-giftcard-home {
    display: flex;
    justify-content: center;
    align-items: center;
}

.h1-giftcard-home {
    color: #000000;
    font-size: 3.5rem;
    text-align: left;
    margin-bottom: 1rem;
}

.p-giftcard-home {
    color: #000000;
    font-size: 2rem;
    font-weight: 300;
    margin-right: 7rem;
    margin-bottom: 2rem;
    line-height: normal;
}

.btn-giftcard-home {
    background: #F39220;
    border: 1px solid #000000;
    border-radius: 50px;
    font-weight: bold;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    transition: ease 0.3s;
}

.btn-giftcard-home:hover {
    background: #000000;
    color: #FFFFFF;
}

.img-bitcoinGiftcard-home {
    height: 3rem;
    margin-bottom: 1rem;
}

.container-pagos-home {
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../assets/img/home/FotoDolaresBitcoin.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 1000px;
    margin-left: 0;
    margin-right: 0;
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.col-container-pagos-home {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-appPago-home {
    height: 32rem;
    margin-top: -3rem;
    margin-left: -10rem;
}

.h1-pagos-home {
    color: #FFFFFF;
    font-size: 3.5rem;
    text-align: left;
    margin-bottom: 1rem;
}

.p-pagos-home {
    color: #FFFFFF;
    font-size: 2rem;
    font-weight: 300;
    margin-right: 7rem;
    margin-bottom: 2rem;
    line-height: normal;
}

.p-pagosServices-home {
    color: #FFFFFF;
}

.container-titleAliados-home {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.h1-titleAliados-home {
    color: #000000;
    font-size: 3.5rem;
    margin-bottom: 1rem;
}

.p-titleAliados-home {
    color: #000000;
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-left: 16rem;
    margin-right: 16rem;
    line-height: normal;
}

.container-clientes-home {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../assets/img/home/FondodeClientes.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 1000px;
    margin-left: 0;
    margin-right: 0;
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.col-aliados-home {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-aliados-home {
    height: auto;
    width: 100%;
}

@media screen and (min-width: 2560px) {
    .container-title-home {
        margin-left: 15%;
        transform: scale(0.8);
    }
    .div-img-celular-home {
        transform: scale(0.8);
    }
    .img-celular-home {
        margin-left: -6rem;
    }

    .col-box-creditos {
        transform: 
            scale(0.9)
            translateY(-60%);
    }

    .img-celular-ditowallet {
    transform: scale(0.8);
    }

    .container-ditowallet-home {
        transform: 
            scale(0.8)
            translateY(-60%);
    }
    
    .container-tarjetaprepago-home {
        transform: scale(0.8);
        margin-left: -26rem;
    }
    
    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-left: 15%;
        transform: scale(0.8);
    }

    .img-giftcard-home {
        transform: scale(0.8);
    }

    .img-appPago-home {
        transform: scale(0.8);
    }
}

@media screen and (min-width: 1920px) and (max-width: 2559px) {
    .container-title-home {
        margin-left: 20%;
    }

    .container-tarjetaprepago-home {
        transform: scale(0.85);
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-bottom: 4rem;
        margin-left: 25%;
        transform: scale(0.9);
    }
}

@media screen and (min-width: 1400px) and (max-width: 1919px) {

    .mastercard-img {
        top: 65%;
        right: 21%;
        width: 30%;
        height: auto;
    }
    
    .container-banner-home {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }
    .row-banner-bg-home {
        height: 700px;
    }

    .container-tarjetaprepago-row {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        background-position: 0% 0%;
    }
    

    .row-tarjetaprepago-home {
        height: 650px;
    }

    .container-pagos-home {
        height: 700px;
    }

    .container-clientes-home {
        height: 700px;
    }

    .container-title-home {
        transform: scale(0.75);
    }

    .div-img-celular-home {
        transform: scale(0.75);
    }

    .img-celular-home {
        margin-left: -4rem;
    }

    .col-box-creditos {
        transform: 
            scale(0.8)
            translateY(-60%);
    }

    .div-container-img-woman {
        transform: scale(0.9);
    }

    .img-circle-woman-home {
        margin-left: 15%;
    }

    .row-ditowallet-home {
        padding-top: 0;
        padding-bottom: 0;
    }

    .img-celular-ditowallet {
        transform: scale(0.75);
        float: right;
        margin-right: -5%;
    }

    .container-ditowallet-home {
        transform: 
            scale(0.8)
            translateY(-65%)
            translateX(0%);
    }

    .container-tarjetaprepago-home {
        transform: scale(0.7);
        margin-left: -25rem;
    }

    .div-container-giftcard {
        transform: 
            scale(0.85)
            translateX(20%);
    }

    .img-giftcard-home {
        transform: 
            scale(0.75)
            translateX(0%);
    }

    .div-container-img-appPago {
        transform: scale(1.1);
        margin-left: 25%;
    }

    .img-appPago-home {
        transform: scale(1);
    }

    .div-container-pagos-title {
        transform: 
            scale(0.7);
    }
}

@media screen and (min-width: 1600px) and (max-width: 1919px) {
    .container-title-home {
        width: 80rem;
        height: 30rem;
        padding: 3rem;
        margin: auto;
        margin-left: 8rem;
        margin-top: 0rem;
    }

    .container-tarjetaprepago-home {
        width: 70rem;
        padding: 3rem;
        margin-left: -10rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem; 
        margin-bottom: 4rem;
        margin-left: 35%;
        transform: scale(0.8);
    }
    
    .h1-tarjetaprepado-home {
        font-size: 4rem;
        text-align: right;
        margin-bottom: 2rem;
    }

    .img-bitcoinprepago-home {
        height: 3.1rem;
        margin-bottom: 1rem;
    } 
    
    .p-tarjetaprepado-home {
        font-size: 2.2rem;
        font-weight: 300;
        margin-left: 16rem;
        margin-bottom: 2rem;
    }

    .div-btn-tarjetaprepago {
        margin-top: 4rem;
        margin-left: 16rem;
    }
}

@media screen and (min-width: 1400px) and (max-width: 1599px) {
    
    .container-title-home {
        width: 80rem;
        margin-left: 0rem !important;
    }

    .h1-title-home {
        font-size: 5rem;
    }
    
    .p-subtitle-home {
        font-size: 2rem;
    }

    .container-creditos-home {
        margin-top: 4.5rem;
        margin-bottom: 4.5rem;
    }

    .container-ditowallet-home {
        transform: 
            scale(0.8)
            translateY(-65%)
            translateX(-5%);
    }

    .container-tarjetaprepago-home {
        width: 65rem;
        padding: 3rem;
        margin-left: -10rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-left: 4rem;
    }
    
    .h1-tarjetaprepado-home {
        font-size: 3.5rem;
        text-align: center;
        margin-bottom: 2rem;
    }

    .img-bitcoinprepago-home {
        height: 3rem;
        margin-bottom: 1rem;
    } 
    
    .p-tarjetaprepado-home {
        font-size: 2rem;
        font-weight: 300;
        margin-left: 9rem;
        margin-bottom: 2rem;
    }

    .div-btn-tarjetaprepago {
        margin-top: 4rem;
        margin-left: 7rem;
    }

    .container-giftcard-home {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    .img-appPago-home {
        height: auto;
        width: 100%;
        margin-top: -3rem;
        margin-left: -2rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem; 
        margin-bottom: 4rem;
        margin-left: 15%;
        transform: scale(0.8);
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .mastercard-img {
        top: 75%;
        width: 35%;
        height: auto;
    }
    
    .container-title-home {
        width: 60rem;
        margin-left: 5rem;
    }

    .h1-title-home {
        font-size: 4.4rem;
    }
    
    .p-subtitle-home {
        font-size: 1.8rem;
        margin-right: 20rem;
    }

    .img-celular-home {
        margin-left: 0;     
    }

    .box-creditos {
        margin: 0.2rem;
    }

    .h1-ditowallet-home {
        font-size: 4rem;
    }
    
    .img-bitcoinWallet-home {
        height: 3.5rem;
    }
    
    .h2-ditowallet-home {
        text-align: center;
        font-size: 2.6rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
    }

    .container-tarjetaprepago-home {
        width: 55rem;
        padding: 3rem;
        margin-left: -10rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-left: 4rem;
        width: 100%;
        height: auto;
    }
    
    .h1-tarjetaprepado-home {
        font-size: 2.5rem;
        text-align: right;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .img-bitcoinprepago-home {
        height: 2.3rem;
        margin-bottom: 1rem;
    } 
    
    .p-tarjetaprepado-home {
        font-size: 1.5rem;
        font-weight: 300;
        margin-left: 18rem;
        margin-bottom: 2rem;
    }

    .div-btn-tarjetaprepago {
        margin-top: 4rem;
        margin-left: 16rem;
    }

    .img-giftcard-home {
        display: table;
        margin: 0 auto;
        height: auto;
        width: 100%;
    }

    .container-pagos-home {
        height: 800px;
    }

    .img-appPago-home {
        height: auto;
        width: 100%;
        margin-top: -3rem;
        margin-left: -2rem;
    }

    .h1-pagos-home {
        font-size: 3.5rem;
        text-align: left;
        margin-bottom: 2rem;
    }
    
    .p-pagos-home {
        font-size: 1.8rem;
        margin-right: 0rem;
    }

    .container-clientes-home {
        height: 800px;
    }
    
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
    .mastercard-img {
        top: 75%;
        width: 45%;
        height: auto;
    }
    .container-title-home {
        width: 50rem;
        height: 24rem;
        margin-left: 3rem;
    }

    .h1-title-home {
        font-size: 4rem;
        margin-right: 4rem;
    }
    
    .p-subtitle-home {
        font-size: 1.8rem;
        margin-right: 8rem;
    }

    .img-celular-home {
        margin-left: 0;
        height: 38rem;
        display: table;
        margin: 0 auto;
    }

    .container-creditos-home {
        margin-bottom: 0;
    }

    .container-box-creditos {
        display: block;
    }

    .img-circle-woman-home {
        height: auto;
        width: 100%;
    }

    .box-creditos {
        margin: 4rem;
        margin-left: 10rem;
        margin-right: 10rem;
        text-align: center;
    }

    .title-box-creditos {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    .p-box-credito {
        width: auto;
        padding-left: 7rem;
        padding-right: 7rem;
    }

    .h1-creditos-home {
        margin-top: -45rem;
        margin-bottom: 45rem;
    }

    .h1-ditowallet-home {
        font-size: 3.5rem;
    }
    
    .img-bitcoinWallet-home {
        height: 3rem;
    }
    
    .h2-ditowallet-home {
        text-align: center;
        font-size: 2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
    }

    .img-celular-ditowallet {
        margin-left: 0rem;
        height: auto;
        width: 100%;
    }

    .img-stores-mobile {
        margin: 1rem;
        width: 16rem;
    }

    .container-tarjetaprepago-row {
        background-position: 90% 0%;
    }

    .container-tarjetaprepago-home {
        height: 52rem;
        width: 38rem;
        padding: 3rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        margin-top: -35rem;
        margin-bottom: 35rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        width: 45%;
        height: auto;
        margin-top: 8rem;
    }
    
    .h1-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 32rem;
    }

    .img-bitcoinprepago-home {
        height: 2rem;
        margin-bottom: 0rem;
    }
    
    .p-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 300;
        margin-left: 0rem;
        margin-bottom: 2rem;
        line-height: normal;
    }
    
    .div-btn-tarjetaprepago {
        margin-top: 3rem;
        margin-left: 0rem;
    }

    .img-giftcard-home {
        display: table;
        margin: 0 auto;
        height: auto;
        width: 100%;
    }

    .h1-giftcard-home {
        font-size: 2.7rem;
        text-align: left;
    }
    
    .p-giftcard-home {
        font-size: 2rem;
        margin-right: 0rem;
    }

    .img-bitcoinGiftcard-home {
        height: 2.4rem;
        margin-bottom: 1rem;
    }

    .container-pagos-home {
        height: 800px;
    }

    .img-appPago-home {
        height: auto;
        width: 100%;
        margin-top: -3rem;
        margin-left: -2rem;
    }

    .h1-pagos-home {
        font-size: 3rem;
        text-align: left;
        margin-bottom: 2rem;
    }
    
    .p-pagos-home {
        font-size: 1.5rem;
        margin-right: 0rem;
    }

    .iconPagosServices-home {
        width: 4rem;
        height: 4rem;
    }

    .container-clientes-home {
        height: 800px;
    }
    
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
    .mastercard-img {
        top: 80%;
        width: 45%;
        height: auto;
    }

    .container-title-home {
        width: 50rem;
        height: 24rem;
        margin-left: 3rem;
    }

    .h1-title-home {
        font-size: 4rem;
        margin-right: 4rem;
    }
    
    .p-subtitle-home {
        font-size: 1.8rem;
        margin-right: 8rem;
    }

    .img-celular-home {
        margin-left: 0;
        height: 38rem;
        display: table;
        margin: 0 auto;
    }

    .container-creditos-home {
        margin-bottom: 0;
    }

    .container-box-creditos {
        display: block;
    }

    .img-circle-woman-home {
        height: auto;
        width: 100%;
    }

    .box-creditos {
        margin: 4rem;
        margin-left: 10rem;
        margin-right: 10rem;
        text-align: center;
    }

    .title-box-creditos {
        padding-left: 8rem;
        padding-right: 8rem;
    }

    .p-box-credito {
        width: auto;
        padding-left: 7rem;
        padding-right: 7rem;
    }

    .h1-creditos-home {
        margin-top: -45rem;
        margin-bottom: 45rem;
    }

    .h1-ditowallet-home {
        font-size: 3.5rem;
    }
    
    .img-bitcoinWallet-home {
        height: 3rem;
    }
    
    .h2-ditowallet-home {
        text-align: center;
        font-size: 2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
    }

    .img-celular-ditowallet {
        margin-left: 0rem;
        height: auto;
        width: 100%;
    }

    .img-stores-mobile {
        margin: 1rem;
        width: 16rem;
    }

    .container-tarjetaprepago-home {
        height: 52rem;
        width: 38rem;
        padding: 3rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        margin-top: -35rem;
        margin-bottom: 35rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        width: 50%;
        height: auto;
        margin-top: 12rem;
    }
    
    .h1-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 32rem;
    }

    .img-bitcoinprepago-home {
        height: 2rem;
        margin-bottom: 0rem;
    }
    
    .p-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 300;
        margin-left: 0rem;
        margin-bottom: 2rem;
        line-height: normal;
    }
    
    .div-btn-tarjetaprepago {
        margin-top: 3rem;
        margin-left: 0rem;
    }

    .img-giftcard-home {
        display: table;
        margin: 0 auto;
        height: auto;
        width: 100%;
    }

    .h1-giftcard-home {
        font-size: 2.7rem;
        text-align: left;
    }
    
    .p-giftcard-home {
        font-size: 2rem;
        margin-right: 0rem;
    }

    .img-bitcoinGiftcard-home {
        height: 2.4rem;
        margin-bottom: 1rem;
    }

    .container-pagos-home {
        height: 800px;
    }

    .img-appPago-home {
        height: auto;
        width: 100%;
        margin-top: -3rem;
        margin-left: -2rem;
    }

    .h1-pagos-home {
        font-size: 3rem;
        text-align: left;
        margin-bottom: 2rem;
    }
    
    .p-pagos-home {
        font-size: 1.5rem;
        margin-right: 0rem;
    }

    .iconPagosServices-home {
        width: 4rem;
        height: 4rem;
    }

    .container-clientes-home {
        height: auto;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    
}

@media screen and (min-width: 868px) and (max-width: 991px) {
    .mastercard-img {
        top: 75%;
        width: 45%;
        height: auto;
    }

    .row-banner-bg-home {
        height: 768px;
    }

    .container-title-home {
        width: 45rem;
        height: 20rem;
        margin-left: 3rem;
    }

    .h1-title-home {
        font-size: 3.5rem;
        margin-right: 4rem;
    }
    
    .p-subtitle-home {
        font-size: 1.5rem;
        margin-right: 8rem;
    }

    .img-celular-home {
        margin-left: 0;
        height: 28rem;
        display: table;
        margin: 0 auto;
    }

    .container-creditos-home {
        margin-bottom: -12rem;
    }

    .container-box-creditos {
        display: block;
    }

    .img-circle-woman-home {
        height: auto;
        width: 100%;
    }

    .box-creditos {
        margin: 4rem;
        text-align: center;
    }

    .title-box-creditos {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .p-box-credito {
        width: auto;
        padding-left: 7rem;
        padding-right: 7rem;
    }

    .h1-creditos-home {
        margin-top: -35rem;
        margin-bottom: 35rem;
    }

    .h1-ditowallet-home {
        font-size: 2.5rem;
    }
    
    .img-bitcoinWallet-home {
        height: 2.4rem;
    }
    
    .h2-ditowallet-home {
        text-align: center;
        font-size: 1.5rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
    }

    .img-celular-ditowallet {
        margin-left: 0rem;
        height: auto;
        width: 100%;
    }

    .img-stores-mobile {
        margin: 1rem;
        width: 12rem;
    }

    .container-tarjetaprepago-row {
        background-position: 90% 0%;
    }

    .container-tarjetaprepago-home {
        height: 52rem;
        width: 38rem;
        padding: 3rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        margin-top: -35rem;
        margin-bottom: 35rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        width: 50%;
        height: auto;
        margin-top: 12rem;
    }
    
    .h1-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 32rem;
    }

    .img-bitcoinprepago-home {
        height: 2rem;
        margin-bottom: 0rem;
    }
    
    .p-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 300;
        margin-left: 0rem;
        margin-bottom: 2rem;
        line-height: normal;
    }
    
    .div-btn-tarjetaprepago {
        margin-top: 3rem;
        margin-left: 0rem;
    }

    .img-giftcard-home {
        display: table;
        margin: 0 auto;
        height: auto;
        width: 100%;
    }

    .h1-giftcard-home {
        font-size: 2rem;
        text-align: left;
    }
    
    .p-giftcard-home {
        font-size: 1.5rem;
        margin-right: 0rem;
    }

    .img-bitcoinGiftcard-home {
        height: 1.8rem;
        margin-bottom: 1rem;
    }

    .container-pagos-home {
        height: 750px;
    }

    .img-appPago-home {
        height: auto;
        width: 100%;
        margin-top: -3rem;
        margin-left: -2rem;
    }

    .h1-pagos-home {
        font-size: 2.3rem;
        text-align: left;
        margin-bottom: 2rem;
    }
    
    .p-pagos-home {
        font-size: 1.3rem;
        margin-right: 0rem;
    }
    
    .p-pagosServices-home {
        font-size: 1rem;
        margin-right: 0rem;
    }

    .iconPagosServices-home {
        width: 1.8rem;
        height: 1.8rem;
    }

    .p-titleAliados-home {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .container-clientes-home {
        height: auto;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 867px) {
    .mastercard-img {
        top: 75%;
        width: 55%;
        height: auto;
    }

    .row-banner-bg-home {
        height: 768px;
    }

    .container-title-home {
        width: 40rem;
        height: 20rem;
        margin-left: 3rem;
    }

    .h1-title-home {
        font-size: 3rem;
        margin-right: 4rem;
    }
    
    .p-subtitle-home {
        font-size: 1.5rem;
        margin-right: 8rem;
    }

    .img-celular-home {
        margin-left: 0;
        height: 28rem;
        display: table;
        margin: 0 auto;
    }

    .container-creditos-home {
        margin-bottom: -12rem;
    }

    .container-box-creditos {
        display: block;
    }

    .img-circle-woman-home {
        height: auto;
        width: 100%;
    }

    .box-creditos {
        margin: 4rem;
        text-align: center;
    }

    .title-box-creditos {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .p-box-credito {
        width: auto;
        padding-left: 7rem;
        padding-right: 7rem;
    }

    .h1-creditos-home {
        margin-top: -35rem;
        margin-bottom: 35rem;
    }

    .h1-ditowallet-home {
        font-size: 2.5rem;
    }
    
    .img-bitcoinWallet-home {
        height: 2.4rem;
    }
    
    .h2-ditowallet-home {
        text-align: center;
        font-size: 1.5rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
    }

    .img-celular-ditowallet {
        margin-left: 0rem;
        height: auto;
        width: 100%;
    }

    .img-stores-mobile {
        margin: 1rem;
        width: 10rem;
    }
    
    .container-tarjetaprepago-row {
        background-position: 90% 0%;
    }

    .container-tarjetaprepago-home {
        height: 52rem;
        width: 38rem;
        padding: 3rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        margin-top: -35rem;
        margin-bottom: 35rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        width: 55%;
        height: auto;
        margin-top: 12rem;
    }
    
    .h1-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 32rem;
    }

    .img-bitcoinprepago-home {
        height: 2rem;
        margin-bottom: 0rem;
    }
    
    .p-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 300;
        margin-left: 0rem;
        margin-bottom: 2rem;
        line-height: normal;
    }
    
    .div-btn-tarjetaprepago {
        margin-top: 3rem;
        margin-left: 0rem;
    }

    .img-giftcard-home {
        display: table;
        margin: 0 auto;
        height: auto;
        width: 100%;
    }

    .h1-giftcard-home {
        font-size: 2rem;
        text-align: left;
    }
    
    .p-giftcard-home {
        font-size: 1.5rem;
        margin-right: 0rem;
    }

    .img-bitcoinGiftcard-home {
        height: 1.8rem;
        margin-bottom: 1rem;
    }

    .container-pagos-home {
        height: 700px;
    }

    .img-appPago-home {
        height: auto;
        width: 100%;
        margin-top: -3rem;
        margin-left: -2rem;
    }

    .h1-pagos-home {
        font-size: 2.3rem;
        text-align: left;
        margin-bottom: 2rem;
    }
    
    .p-pagos-home {
        font-size: 1.3rem;
        margin-right: 0rem;
    }
    
    .p-pagosServices-home {
        font-size: 1rem;
        margin-right: 0rem;
    }

    .iconPagosServices-home {
        width: 1.8rem;
        height: 1.8rem;
    }

    .p-titleAliados-home {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .container-clientes-home {
        height: auto;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

@media screen and (max-width: 767px) {
    .mastercard-img {
        top: 115%;
        width: 50%;
        left: 20%;
        height: auto;
    }

    .container-title-home {
        width: 38rem;
        height: 30rem;
        padding: 3rem;
        margin-left: 0rem;
        margin: auto;
        margin-top: 6rem;
    }
    
    .h1-title-home {
        color: #FFFFFF;
        font-size: 3rem;
        text-align: center;
        margin-right: 0rem;
    
    }
    
    .p-subtitle-home {
        color: #FFFFFF;
        font-size: 1.5rem;
        text-align: center;
        margin-right: 0rem;
    }
    
    .img-celular-home {
        margin-left: 0rem;
        height: 36rem;
        display: table;
        margin: 0 auto;
        margin-top: -16rem;
    }

    .container-creditos-home {
        margin-bottom: 0rem;
    }

    .container-box-creditos {
        display: block;
    }
    
    .box-creditos {
        margin: 1rem;
        text-align: center;
    }
    
    .title-box-creditos {
        color: #FFFFFF;
    }
    
    .p-box-credito {
        width: auto;
    }

    .h1-creditos-home {
        font-size: 3rem;
        margin-top: -25rem;
        margin-bottom: 27rem;
    }

    .img-bitcoin-home {
        height: 2rem;
    }

    .img-circle-woman-home {
        height: auto;
        width: 100%;
    }

    .img-celular-ditowallet {
        margin-left: 0rem;
        height: 52rem;
        display: table;
        margin: 0 auto;
        margin-top: -4rem;
    }

    .h1-ditowallet-home {
        color: #FFFFFF;
        font-size: 3rem;
        text-align: center;
    }
    
    .img-bitcoinWallet-home {
        height: 2.5rem;
        margin-bottom: 1rem;
    }
    
    .h2-ditowallet-home {
        font-size: 1.6rem;
        text-align: center;
        margin-right: 4rem;
        margin-left: 4rem;
        margin-bottom: 2rem;
    }
    
    .img-stores-mobile {
        height: 4rem;
        margin: 0.5rem;
        margin-top: 48rem;
        margin-bottom: -56rem;
    }

    .container-tarjetaprepago-row {
        background-position: 90% 0%;
    }

    .container-tarjetaprepago-home {
        height: 52rem;
        width: 38rem;
        padding: 3rem;
        margin-left: 0rem;
        margin: auto;
        display: table;
        margin: 0 auto;
        margin-top: -35rem;
        margin-bottom: 35rem;
    }

    .img-tarjetasprepago-home {
        margin-right: 0rem;
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        width: 65%;
        height: auto;
        margin-top: 12rem;
    }
    
    .h1-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 32rem;
    }

    .img-bitcoinprepago-home {
        height: 2rem;
        margin-bottom: 0rem;
    }
    
    .p-tarjetaprepado-home {
        color: #FFFFFF;
        font-size: 1.5rem;
        text-align: center;
        font-weight: 300;
        margin-left: 0rem;
        margin-bottom: 2rem;
        line-height: normal;
    }
    
    .div-btn-tarjetaprepago {
        margin-top: 3rem;
        margin-left: 0rem;
    }

    .img-giftcard-home {
        display: table;
        margin: 0 auto;
        height: auto;
        width: 100%;
    }

    .h1-giftcard-home {
        text-align: center;
    }

    .p-giftcard-home {
        text-align: center;
        margin-right: 0rem;
    }
    
    .img-bitcoinGiftcard-home {
        text-align: center;
    }
    
    .btn-giftcard-home {
        display: table;
        margin: 0 auto;
    }

    .container-pagos-home {
        background-position: 35% 0%;
        height: auto;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    .img-appPago-home {
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-top: 2rem;
    }

    .h1-pagos-home {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1rem;
    }
    
    .p-pagos-home {
        font-size: 1.4rem;
        text-align: center;
        margin-right: 0rem;
        margin-left: 3rem;
        margin-right: 3rem;
        margin-bottom: 2rem;
    }

    .iconPagosServices-home {
        width: 3.5rem;
        height: 3.5rem;
    }

    .btn-info-white {
        display: table;
        margin: 0 auto;
    }

    .h1-titleAliados-home {
        font-size: 2.5rem;
    }
    
    .p-titleAliados-home {
        font-size: 1.5rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .container-clientes-home {
        padding-top: 2rem;
        padding-bottom: 2rem;
        height: auto;
        width: 100%;
    }

    .img-aliados-home {
        height: auto;
        width: 100%;
    }
}

@media screen and (min-width: 512px) and (max-width: 667px) {
    .mastercard-img {
        top: 100%;
        width: 60%;
        height: auto;
    }

    .container-title-home {
        width: 30rem;
        height: 30rem;
        padding: 3rem;
        margin-left: 0rem;
        margin: auto;
        margin-top: 6rem;
    }

    .container-tarjetaprepago-row {
        height: 800px;
    }
    
    .h1-title-home {
        font-size: 2.5rem;
        text-align: center;
    }
    
    .p-subtitle-home {
        font-size: 1.2rem;
        text-align: center;
        margin-right: 0rem;
    }
    
    .img-celular-home {
        margin-left: 0rem;
        height: 36rem;
        display: table;
        margin: 0 auto;
        margin-top: -16rem;
    }

    .img-celular-ditowallet {
        margin-top: -4rem;
    }

    .container-tarjetaprepago-home {
        height: 44rem;
        width: 30rem;
        padding: 2.5rem;
        margin-left: 0rem;
        margin: auto;
        margin-top: -44rem;
    }

    .img-tarjetasprepago-home {
        height: auto;
        margin-top: 6rem;
    }
    
    .h1-tarjetaprepado-home {
        font-size: 2rem;
        margin-bottom: 28rem;
    }

    .img-bitcoinprepago-home {
        height: 1.8rem;
        margin-bottom: 0rem;
    }
    
    .p-tarjetaprepado-home {
        font-size: 1.2rem;
    }
    
}

@media screen and (min-width: 391px) and (max-width: 511px) {

    .mastercard-img {
        top: 110%;
        width: 65%;
        height: auto;
    }

    .container-banner-home {
        height: 900px;
    }

    .container-tarjetaprepago-row {
        height: 800px;
    }
   
    .container-title-home {
        width: 22rem;
        height: 22rem;
        padding: 2rem;
        margin-left: 0rem;
        margin: auto;
        margin-top: 4rem;
    }
    
    .h1-title-home {
        font-size: 2rem;
        text-align: center;
    }
    
    .p-subtitle-home {
        font-size: 1rem;
        text-align: center;
        margin-right: 0rem;
    }
    
    .img-celular-home {
        margin-left: 0rem;
        height: 32rem;
        display: table;
        margin: 0 auto;
        margin-top: -22rem;
    }

    .h1-creditos-home {
        font-size: 2.5rem;
        margin-top: -21.8rem;
        margin-bottom: 22rem;
    }

    .title-box-creditos {
        padding: 2rem 2rem 1rem 2rem;
    }

    .img-bitcoin-home {
        height: 1.5rem;
        margin-bottom: 0.3rem;
    }

    .p-box-credito {
        font-size: 1.2rem;
        text-align: center;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .img-celular-ditowallet {
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        height: 36rem;
    }

    .h1-ditowallet-home {
        font-size: 2.4rem;
        text-align: center;
    }
    
    .img-bitcoinWallet-home {
        height: 2.1rem;
        margin-bottom: 0.5rem;
    }
    
    .h2-ditowallet-home {
        font-size: 1.5rem;
        text-align: center;
        margin-right: 1rem;
        margin-left: 1rem;
        margin-bottom: 2rem;
    }
    
    .img-stores-mobile {
        height: 3rem;
        margin: 0.5rem;
        margin-top: 38rem;
        margin-bottom: -56rem;
    }

    .row-tarjetaprepago-home {
        height: 850px;
        background-position: 90% 0%;
    }

    .container-tarjetaprepago-home {
        height: 38rem;
        width: 22rem;
        padding: 2rem;
        margin-left: 0rem;
        margin: auto;
        margin-top: -36rem;
    }

    .img-tarjetasprepago-home {
        height: auto;
        margin-top: 4rem;
    }
    
    .h1-tarjetaprepado-home {
        font-size: 1.4rem;
        margin-bottom: 24rem;
    }

    .img-bitcoinprepago-home {
        height: 1.3rem;
        margin-bottom: 0.1rem;
    }
    
    .p-tarjetaprepado-home {
        font-size: 0.9rem;
    }

    .h1-giftcard-home {
        font-size: 2.5rem;
    }

    .p-giftcard-home {
        font-size: 1.5rem;
    }
    
    .img-bitcoinGiftcard-home {
        height: 2.1rem;
    }
}

@media screen and (min-width: 320px) and (max-width: 390px) {

    .mastercard-img {
        top: 105%;
        width: 75%;
        height: auto;
    }

    .container-tarjetaprepago-row {
        height: 820px;
    }

    .container-banner-home {
        height: 900px;
    }
   
    .container-title-home {
        width: 18rem;
        height: 18rem;
        padding: 2rem;
        margin-left: 0rem;
        margin: auto;
        margin-top: 3rem;
    }
    
    .h1-title-home {
        font-size: 1.6rem;
        text-align: center;
    }
    
    .p-subtitle-home {
        font-size: 1rem;
        text-align: center;
        margin-right: 0rem;
    }
    
    .img-celular-home {
        margin-left: 0rem;
        height: 30rem;
        display: table;
        margin: 0 auto;
        margin-top: -22rem;
    }

    .h1-creditos-home {
        font-size: 2rem;
        margin-top: -18rem;
        margin-bottom: 18rem;
    }

    .title-box-creditos {
        color: #FFFFFF;
        padding: 2rem 1.3rem 1rem 1.3rem;
    }

    .img-bitcoin-home {
        height: 1.4rem;
        margin-bottom: 0.3rem;
    }

    .p-box-credito {
        font-size: 1.1rem;
        text-align: center;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
    }

    .img-celular-ditowallet {
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        height: 28rem;
    }

    .h1-ditowallet-home {
        font-size: 2rem;
        text-align: center;
    }
    
    .img-bitcoinWallet-home {
        height: 1.8rem;
        margin-bottom: 0.5rem;
    }
    
    .h2-ditowallet-home {
        font-size: 1.1rem;
        text-align: center;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
    }
    
    .img-stores-mobile {
        height: 2.5rem;
        margin: 0.5rem;
        margin-top: 30rem;
        margin-bottom: -56rem;
    }

    .row-tarjetaprepago-home {
        height: 850px;
        background-position: 90% 0%;
    }

    .container-tarjetaprepago-home {
        height: 38rem;
        width: 18rem;
        padding: 1.5rem;
        margin-left: 0rem;
        margin-right: 0rem;
        margin: auto;
        margin-top: -36rem;
    }
    
    .btn-box-creditos {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .img-tarjetasprepago-home {
        height: auto;
        margin-top: 4rem;
    }
    
    .h1-tarjetaprepado-home {
        font-size: 1.4rem;
        margin-bottom: 24rem;
    }

    .img-bitcoinprepago-home {
        height: 1.3rem;
        margin-bottom: 0.1rem;
    }
    
    .p-tarjetaprepado-home {
        font-size: 0.9rem;
    }

    .h1-giftcard-home {
        font-size: 2.5rem;
    }

    .p-giftcard-home {
        font-size: 1.5rem;
    }
    
    .img-bitcoinGiftcard-home {
        height: 2.1rem;
    }

    .h1-pagos-home {
        font-size: 2.2rem;
        text-align: center;
        margin-bottom: 1rem;
    }
    
    .p-pagos-home {
        font-size: 1.2rem;
        text-align: center;
        margin-right: 0rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 2rem;
    }

    .iconPagosServices-home {
        width: 2.5rem;
        height: 2.5rem;
    }

    .p-pagosServices-home {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 319px) {

    .container-banner-home {
        height: 850px;
    }

    .container-tarjetaprepago-row {
        height: 800px;
    }
   
    .container-title-home {
        width: 16rem;
        height: 20rem;
        padding: 1.5rem;
        margin-left: 0rem;
        margin: auto;
        margin-top: 4rem;
    }
    
    .h1-title-home {
        font-size: 1.5rem;
        text-align: center;
    }
    
    .p-subtitle-home {
        font-size: 0.9rem;
        text-align: center;
        margin-right: 0rem;
    }
    
    .img-celular-home {
        margin-left: 0rem;
        height: 24rem;
        display: table;
        margin: 0 auto;
        margin-top: -24rem;
    }

    .h1-creditos-home {
        font-size: 1.5rem;
        margin-top: -15rem;
        margin-bottom: 15rem;
    }

    .title-box-creditos {
        color: #FFFFFF;
        padding: 2rem 1.3rem 1rem 1.3rem;
    }

    .img-bitcoin-home {
        height: 1.4rem;
        margin-bottom: 0.3rem;
    }

    .p-box-credito {
        font-size: 1.1rem;
        text-align: center;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
    }

    .img-celular-ditowallet {
        margin-left: 0rem;
        display: table;
        margin: 0 auto;
        height: 28rem;
    }

    .h1-ditowallet-home {
        font-size: 2rem;
        text-align: center;
    }
    
    .img-bitcoinWallet-home {
        height: 1.8rem;
        margin-bottom: 0.5rem;
    }
    
    .h2-ditowallet-home {
        font-size: 1.1rem;
        text-align: center;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
    }
    
    .img-stores-mobile {
        height: 2.1rem;
        margin: 0.5rem;
        margin-top: 30rem;
        margin-bottom: -56rem;
    }

    .row-tarjetaprepago-home {
        height: 850px;
        background-position: 90% 0%;
    }

    .container-tarjetaprepago-home {
        height: 38rem;
        width: 16rem;
        padding: 1.5rem;
        margin-left: 0rem;
        margin-right: 0rem;
        margin: auto;
        margin-top: -36rem;
    }
    
    .btn-box-creditos {
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.9rem;
    }

    .img-tarjetasprepago-home {
        height: 14rem;
        margin-top: 4rem;
    }
    
    .h1-tarjetaprepado-home {
        font-size: 1.3rem;
        margin-bottom: 24rem;
    }

    .img-bitcoinprepago-home {
        height: 1.3rem;
        margin-bottom: 0.1rem;
    }
    
    .p-tarjetaprepado-home {
        font-size: 0.9rem;
    }

    .h1-giftcard-home {
        font-size: 2.5rem;
    }

    .p-giftcard-home {
        font-size: 1.5rem;
    }
    
    .img-bitcoinGiftcard-home {
        height: 2.1rem;
    }

    .h1-pagos-home {
        font-size: 2rem;
    }
    
    .p-pagos-home {
        font-size: 1rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 2rem;
    }

    .iconPagosServices-home {
        width: 2.5rem;
        height: 2.5rem;
    }

    .p-pagosServices-home {
        font-size: 0.8rem;
        text-align: center;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}