.bg-color{background-color: #000000 !important; color: #ffffff !important;}
/* .bg-color{background-color: #262E38 !important; color: #ffffff !important;} */
.social-icon{opacity: 0.6;}
.social-icon:hover{opacity: 1;}
.ts-foot{font-size: 12px;}

.text-footer-v2 {
    color: #ffffff;
}

.img-logo-ditobanx {
    /* max-height: 4rem; */
    max-width: 10.2rem;
}