.h1-custom {
  color: #C4C4C4 !important;
  font-size: 22px;
}

.custom-featured-image {
  width: 95%;
  height: 85%; /* Ajusta el valor de altura aquí */
  object-fit: cover;
  margin: -5% 0 0;
  padding: 0;
  transform: translatey(10%);
}

.f-bange-custom {
  font-size: 12px;
}

.color-custom-date {
  color: #C4C4C4;
}

.custom-margin-col {
  margin-left: -15px;
  margin-right: -15px;
}

.bg-card-custom {
  background-color: #F5F5F5 !important;
  border-radius: 8px;
}

.vertical-align-custom {
  display: flex;
  align-items: center;
}

.text-justify {
  text-align: justify;
}

@media (max-width: 375px) { 
  .custom-margin-col {
    margin-left: 0;
    margin-right: 0;
  }
}