.navbar-custom{border-radius: 18px !important;}

#dropdown-button-drop-end {
    padding-left: 15px;
}

.img-ditobanx-navbar {
    width: 170px;
}


.ditobanx-btn-navbar {
    width: 7rem;
}

.btn-giftcard-navbar {
    background: #F39220;
    /* border: 1px solid #000000; */
    /* border-radius: 50px;
    font-weight: bold;
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem; */
}

.social-icon-navbar {
    width: 1.5rem;
    height: 1.5rem;
}

@media screen and (max-width: 360px) {
    .text-navbar-whatsapp {
        font-size: 0.8rem;
    }

    .social-icon-navbar {
        width: 1.3rem;
        height: 1.3rem;
    }
    
}

@media screen and (max-width: 320px) {
    .img-ditobanx-navbar {
        width: 130px;
    }

    .logo-ditobanx-navbar {
        width: 130px;
    }

    .text-navbar-whatsapp {
        font-size: 0.6rem;
    }

    .social-icon-navbar {
        width: 1.1rem;
        height: 1.1rem;
    }

    .ditobanx-btn-navbar {
        width: 5rem;
    }
}

@media screen and (max-width: 1199px) {

    .ditobanx-btn-navbar {
        width: auto;
    }
}





    
