#embedded-mobile {
    min-height: 667PX;
    min-width: 321px;
  }
#embedded-desktop{
  min-width: 895px;
    min-height: 590PX;
  }


@media (min-width: 769px){
  .embedded-mobile{
      display: none;
    }
    .embedded-desktop{
      display: block;
    }
}

@media (max-width: 768px){
  .embedded-mobile{
      display: block;
    }
    .embedded-desktop{
      display: none;
    }
}