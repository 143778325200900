@import url("https://fonts.googleapis.com/css?family=Roboto");
body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dropdown-item:active{background-color: #000000 !important; color: #ffffff !important}
.btn-outline-dark:hover{color: #ffffff !important;}
/* .dropdown-menu.show:active{background-color: #000000 !important; color: #ffffff !important}  */
