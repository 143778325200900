.BannerImg {
  background-image: url("../../assets/img/Pos Fisico - Dito Pay Terminal de pagos bitcoin.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.BtcText {
  font-weight: 800;
  font-family: Ubuntu;
  font-style: italic;
}
.perheight {
  height: 48vh;
}

.rounded-6 {
  border-radius: 25px;
}
.rounded-img-6 {
  border-radius: 20px 20px 0px 0px;
}

.text-bitcoin {
  color: #444444 !important;
}
.ptext-18 {
  font-size: 18px !important;
}

.image-size-icon {
  width: 5rem;
  height: auto;
}

.mrtop {
  margin-top: 90px;
}

.bg-colorgray {
  background-color: #f8f9fa !important;
  /* background-color: #909090 !important; */
}
.text-callaction {
  font-size: 2rem;
}
.bg-000000 {
  background-color: #000000 !important;
}

img.bitcoinimage:hover {
  display: block;
  /*background-color: #f7931a !important;*/
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("https://ditopay.io/bitcoin.svg") no-repeat;
  background-size: cover;
  width: 180px;
  height: 180px;
  padding-bottom: 180px;
  transition-timing-function: linear;
}

@media (max-width: 414px) {
  .perheight {
    height: 14vh;
  }
  .BannerImg {
    background-position-x: center;
  }
  .image-size-icon {
    width: 45px;
    height: auto;
  }
}

/* .custom-box-client {
  min-width: 180px !important;
  max-width: 180px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  width: 50% !important;
  margin-top: 0 !important;
  margin: 0px !important;
} */
