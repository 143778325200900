.link {
  color: rgb(177, 50, 50);
  font-size: 20px;
}

.TextBoldTitle {
  font-family: Roboto;
  color: #ffffff;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 38px;
  line-height: 46px;
}

.PrimaryColor {
  color: #0d6efd !important;
}
.SecondaryColor {
  color: #f7931a !important;
}
.BtcText {
  color: #f7931a !important;
  font-family: Ubuntu;
  font-style: italic;
}
.TextBolder {
  font-weight: 900 !important;
}
.ptext18{font-size: 18px !important;}
@import url("https://fonts.googleapis.com/css?family=Ubuntu");
.FBitcoin {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 38px;
  color: #f7931a;
}

img.bitcoinimage:hover {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* background: url("https://ditopay.io/bitcoin.svg") no-repeat; */
  background-size: cover;
  filter: drop-shadow(0px 0px 8px #f7931a);
  transition-timing-function: linear;
}

img.bitcoinimage {
  width: 160px;
  padding: 10px;
}

.margin-row {
  margin-right: 0;
  margin-left: 0;
}

.bgbanner {
  background-image: url("../../assets/img/Banner-woman.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.customhbaner {
  height: 600px;
}

@media (max-width: 575px) {
  .bgbanner {
    background-image: url("../../assets/img/Banner-woman.jpg");
    background: linear-gradient(rgba(36, 36, 36, 0.8), rgba(36, 36, 36, 0.8)), url("../../assets/img/Banner-woman.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  }

  .subtitleIncioText {
    color: #e0dfdf !important;
  }

  img.bitcoinimage {
    width: 120px;
  }
}

@media (max-width: 414px) {
  .bgbanner {
    background-image: url("../../assets/img/banner-mv.jpg") !important;
    background-size: cover !important;
  }
  .customhbaner {
    height: unset !important;
  }

  .TextBoldTitle {
    font-size: 36px;
    margin-top: 3rem;
  }

  .subtitleIncioText {
    padding: 1rem;
  }
}


/*@media (min-width: 1400px)
{
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
    {
        max-width: 1320px;
    }
}*/
/*.BgGray{background-color:#F5F7FA !important;}*/

@media screen and (max-width: 1920px) {
  /* insert styles here */
  .margin-row {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }

}



