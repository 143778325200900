:root {
    --primary: #F79327;
    --dark: #464646;
    --green: #36CC72;
    --black: #000000;
    --textlight: #ffffff;
    --light: #f7f6fb;
    --lightbg: #F1F1F1;
    --gray: #38383894;
  }

* {
    font-family: 'Roboto', sans-serif;
}

.row-banner-bg {
    background-image: url('../../assets/img/ditowallet/bgwallet.jpeg'); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 1000px;
    margin-left: 0;
    margin-right: 0;
    z-index: 0;
}

.title-black {
    font-family: 'Roboto', sans-serif;
    color: #000000;
    font-size: 2.5rem;  
    font-weight: 900;
    
}

.row-container-title {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-title {
    text-align: center;
    margin-left: 10%;
}

.h1-text-wallet {
   font-weight: bold;
   font-size: 4.8rem;
   color: var(--textlight);
}

.h1-text-wallet-prox {
   font-weight: bold;
   font-size: 3.5rem;
   color: var(--textlight);
}

.h1-text-wallet-description {
   font-weight: bold;
   font-size: 2rem;
   color: var(--textlight);
}

.title-separator-wallet {
    border-bottom: 2px solid var(--textlight);
    margin-bottom: 1rem;
    margin-top: 1rem;
    width: 50rem;
    margin-left: 2rem;
}

.img-bitcoin {
    max-height: 3.5rem;
}

.text-align-center {
    text-align: center;
}

.bold-wallet {
    font-weight: bold;
    margin-bottom: 0;
    color: #000000;
}

.p-text-wallet {
    font-size: 1.5rem;
    color: #000000;
    font-weight: 600;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.container-mastercard-bg {
    background-color: #000000;
    text-align: center;
    margin-top: 4rem;
}

.h1-text-mastercard {
    font-weight: bold;
    color: var(--textlight);
}

.remove-margin-padding-RL {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.img-cards {
    float: left;
    height: 42rem;
    object-fit: cover;
    object-position: -20% 0;
}

.div-beneficios-cards {
    margin-left: 14rem;
}

.container-beneficios-list {
    margin-left: 15%;
    float: right;
    padding-top: 3rem;
}

.container-beneficios-list h3 {
    font-size: 1.5rem;
    color: #000000;
    font-weight: 300;
}

.last-h2-beneficios {
    margin-top: 6rem;
    z-index: 100;
    font-size: 1.5rem;
    font-weight: 900;
}

.container-icons-dark-beneficios {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 2rem;
}

.container-icons-dark-beneficios h2 {
    color: #000000;
    font-size: 1.5rem;
    font-weight: 900;
    margin-left: 1rem;
}

.icons-dark-beneficios {
    height: 29px;
    width: 29px;
}

.container-form-wallet {
    text-align: center;
}

.container-input-form-wallet {
    padding-left: 3rem;
    padding-right: 3rem;
}

.text-form-wallet {
    font-size: 1.3rem;
}

.label-form-wallet {
    margin-top: 3rem;
    font-size: 1.3rem;
}

.input-form-wallet {
    font-size: 1.3rem;
    border: none;
    border-radius: 10px;
    height: 3rem;
    border-bottom: 1px solid var(--black);
    text-align: center;
}

.btn-form-wallet {
    background: var(--lightbg);
    border: none;
    border-radius: 50px;
    color: '#000000';
    font-weight: bold;
    font-size: 1.2rem;
    /* padding: 1rem; */
    padding: .5rem;
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
    width: 10rem;
}

.btn-form-wallet:hover {
    background: var(--dark);
    color: var(--textlight);
}

.col-div-container-phone {
    display: flex;
    justify-content: left;
    align-items: center;
}

.container-handphone {
    color: var(--textlight);
    margin-right: 15%;
    margin-left: 10%;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    float: right;
}

.container-handphone h1 {
    font-size: 3.5rem;
    font-weight: 900;
}

.img-phone-white {
    width: 100%;
    margin: auto;
    display: block;
}

.img-hand {
    margin-top: -6.5rem;
    width: 52rem;
    float: right;
}

.col-phone-white-right {
    text-align: right;
    width: 23rem;
    margin-top: 2rem;
}

.col-phone-white-left {
    text-align: left;
    width: 23rem;
    margin-top: 2rem;
}

.col-container-phone-white-left {
    margin-top: 8rem;
}

.col-container-phone-white-right  {
    margin-top: 8rem;
}

@media screen and (min-width: 2559px) {
    .row-container-title {
        justify-content: right;
    }

    .container-title {
        margin-left: 0;
        margin-right: 15%;
    }

    .row-banner-bg {
        height: 1400px;
    }
   
}


@media screen and (min-width: 1625px) {
    .container-beneficios-list {
        margin-left: 20%;
        float: right;
    }   
}

@media screen and (max-width: 1700px) {
    .container-title {
    text-align: center;
    margin-left: 10%;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 4.8rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 3.5rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 2rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 40rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 3.5rem;
    }
}

@media screen and (min-width: 1900px) {

    .container-beneficios-list {
        margin-left: 30%;
        float: right;
    }  
    
    .img-cards {
        width: 90%;
        height: auto;
    }
}

@media screen and (max-width: 1440px) {
    .container-title {
    text-align: center;
    margin-left: 10%;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 4.2rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 3.2rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1.8rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 38rem;
        margin-left: .5rem;
    }

    .img-bitcoin {
        max-height: 3.5rem;
    }

    .row-banner-bg {
        height: 800px;
    }

    .img-phone-white {
        width: auto;
        height: 100%;
    }

    .col-phone-white-right {
        width: 19rem;
        margin-top: 0;
    }

    .col-phone-white-left {
        width: 19rem;
        margin-top: 0;
    }

    .col-container-phone-white-left {
        margin-top: 5rem;
    }

    .col-container-phone-white-right {
        margin-top: 5rem;
    }

    .p-text-wallet {
        font-size: 1.2rem;
        color: #000000;
        font-weight: 600;
    }

    .img-cards {
        height: 36rem;
    }
    
}

@media screen and (max-width: 1350px) {

    .container-icons-dark-beneficios h2 {
        font-size: 1.3rem;
    }
    
    .container-beneficios-list h3 {
        font-size: 1.3rem;
    }

    .last-h2-beneficios {
        font-size: 1.3rem;
    }
    
}

@media screen and (max-width: 1280px) {

    .container-icons-dark-beneficios h2 {
        font-size: 1.3rem;
    }
    
    .container-beneficios-list h3 {
        font-size: 1.3rem;
    }

    .last-h2-beneficios {
        font-size: 1.3rem;
        margin-top: 4rem;
    }
    
}



@media screen and (max-width: 1199px) {
    .container-title {
    text-align: center;
    margin-left: 10%;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 3rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 2rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 20rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 2.5rem;
    }

    .container-title {
        margin-left: 23%;
    }
    
    .col-phone-white-left, .col-phone-white-right {
        width: 16.5rem;
        margin-left: 0;
        margin-right: 0;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 3rem;
    }

    .p-text-wallet {
        font-size: 1.05rem;
    }

    .container-beneficios-list {
        margin-left: 2rem;
    }

    .icons-dark-beneficios {
        height: 21px;
        width: 21px;
    }

    .container-icons-dark-beneficios {
        margin-top: 1.5rem;
    }

    .container-icons-dark-beneficios h2 {
        font-size: 1.22rem;
    }
    
    .container-beneficios-list h3 {
        font-size: 1.22rem;
        margin-right: 10%;
    }

    .last-h2-beneficios {
        font-size: 1.22rem;
    }

    .row-banner-bg {
        height: 600px;
    }

    .col-container-phone-white-left, .col-container-phone-white-right {
        margin-top: 0;
    }

    .img-cards {
        height: 36rem;
        object-position: 0% 0;
    }
    
}

/* Tablets */

@media screen and (max-width: 1024px) {
    .container-title {
    text-align: center;
    margin-left: 10%;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 2rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 20rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 2.5rem;
    }

    .container-beneficios-list {
        margin-left: 2rem;
    }

    .icons-dark-beneficios {
        height: 19px;
        width: 19px;
    }

    .container-icons-dark-beneficios {
        margin-top: 1.5rem;
    }

    .container-icons-dark-beneficios h2 {
        font-size: 1.1rem;
    }
    
    .container-beneficios-list h3 {
        font-size: 1.1rem;
        margin-right: 10%;
    }

    .last-h2-beneficios {
        font-size: 1.1rem;
        margin-top: 3rem;
    }

    .img-cards {
        height: 540px;
        object-position: 0% 0;
    }

    
}

@media screen and (max-width: 991px) {
    .container-title {
    text-align: center;
    margin-left: 10%;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 2rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 20rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 2.5rem;
    }

    .container-white-phone {
        max-width: 990px;
    }

    .col-phone-white-left, .col-phone-white-right {
        width: 15rem;
        margin-left: 0;
        margin-right: 0;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 2.6rem;
    }

    .p-text-wallet {
        font-size: 0.95rem;
    }

    .icons-dark-beneficios {
        height: 19px;
        width: 19px;
    }

    .container-icons-dark-beneficios {
        margin-top: 1.5rem;
    }

    .container-icons-dark-beneficios h2 {
        font-size: 1.1rem;
    }
    
    .container-beneficios-list h3 {
        font-size: 1.1rem;
        margin-right: 0%;
    }

    .last-h2-beneficios {
        font-size: 1.1rem;
        margin-top: 2.5rem;
    }

    .img-cards {
        height: 500px;
        object-position: 0% 0;
    }

    .container-form-wallet {
        box-shadow: none;
        margin-top: 1rem;
        margin-bottom: 0;
        padding: 0;
    }

    .container-form {
        box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .label-form-wallet {
        font-size: 1.2rem;
    }

    .text-form-wallet {
        font-size: 1.2rem;
    }
    
    .bg-handphone {
        height: 460px;
    }

    .img-hand {
        width: 40rem;
        height: 44rem;
        object-fit: cover;
        object-position: 10% 0;
    }

    .container-handphone {
        float: left;
        transform: translateY(100%);
    }

    .container-img-handphone {
        width: 28rem;
        float: right;
    }
    
}

@media screen and (max-width: 868px) {

    .container-white-phone {
        max-width: 800px;
    }

    .col-phone-white-left, .col-phone-white-right {
        width: 13.5rem;
        margin-left: 0;
        margin-right: 0;
    }

    .col-phone-white-left-text, .col-phone-white-right-text {
        margin-top: 2rem !important;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 2.4rem;
    }

    .p-text-wallet {
        font-size: 0.9rem;
    }

    .h1-text-mastercard {
        font-size: 2rem;
    }

    .icons-dark-beneficios {
        height: 19px;
        width: 19px;
    }

    .container-icons-dark-beneficios {
        margin-top: 1.35rem;
    }

    .container-icons-dark-beneficios h2 {
        font-size: 1.1rem;
    }
    
    .container-beneficios-list h3 {
        font-size: 1rem;
        margin-right: 0%;
    }

    .last-h2-beneficios {
        font-size: 1.1rem;
        margin-top: 2.5rem;
    }

    .img-cards {
        height: 450px;
        margin-top: 0rem;
        object-position: 0% 0;
    }

    .img-hand {
        width: 36rem;
        height: 40rem;
        object-position: -20% 0;
    }

    .container-handphone {
        float: left;
        transform: translateY(120%);
    }

    .container-img-handphone {
        width: 28rem;
        float: right;
    }

    .container-handphone h1 {
        font-size: 3rem;
    }

    .col-div-container-phone h3 {
        font-size: 1.3rem;
    }

    .col-div-container-phone img {
        height: 1.2rem;
    }
    
}


@media screen and (max-width: 768px) {
    .row-container-title {
        justify-content: left;
    }

    .container-title {
        margin-right: 1rem;
    }

    .title-separator-wallet {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        width: 30rem;
    }

    .h1-text-wallet {
        font-size: 2.5rem;
    }

    .img-bitcoin {
        max-height: 1.5rem;
    }
    /* .container-title {
    text-align: center;
    margin-left: 10%;
    } */

    /* .h1-text-wallet {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    } */

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 2rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    /* .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 20rem;
        margin-left: 3rem;
    } */

    /* .img-bitcoin {
        max-height: 2.5rem;
    } */

    .container-white-phone {
        max-width: 700px;
    }
    
    .col-phone-white-left, .col-phone-white-right {
        width: 11.5rem;
        margin-left: 0;
        margin-right: 0;
    }

    .col-phone-white-left-text, .col-phone-white-right-text {
        margin-top: 1.5rem !important;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 2.2rem;
    }

    .p-text-wallet {
        font-size: 0.8rem;
    }

    .container-beneficios-list {
        margin-left: 2rem;
    }

    .container-beneficios-list h3 {
        margin-right: 3%;
    }

    .container-icons-dark-beneficios {
        margin-top: 1.2rem;
    }

    .img-cards {
        height: 440px;
        margin-top: 0rem;
        object-position: 0% 0;
    }
    
    .label-form-wallet {
        font-size: 1.1rem;
    }

    .text-form-wallet {
        font-size: 1.1rem;
    }

    .row-banner-bg {
        height: 700px;
        background-position: 100%;
    }

    .container-handphone h1 {
        font-size: 2.5rem;
    }

    .btn-form-wallet {
        font-size: 1.2rem;
        width: 15rem;
    }

    .img-hand {
        width: 30rem;
        height: 32rem;
        object-position: -20% 0;
        margin-top: -3rem;
    }

    .container-handphone {
        float: left;
        transform: translateY(120%);
        margin-left: 5%;
    }

    .container-img-handphone {
        width: 22rem;
    }
   
}

@media screen and (max-width: 668px) {

    .container-title {
        margin-left: 8%;
    }

    .container-white-phone {
        max-width: 600px;
    }
    
    .col-phone-white-left, .col-phone-white-right {
        width: 10rem;
        margin-left: 0;
        margin-right: 0;
    }

    .col-phone-white-left-text, .col-phone-white-right-text {
        margin-top: 1rem !important;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 1.9rem;
    }

    .p-text-wallet {
        font-size: 0.8rem;
    }

    .h1-text-mastercard {
        font-size: 1.8rem;
    }
    
    .container-beneficios-list h3 {
        font-size: 00.9rem;
        margin-right: 0%;
    }

    .container-icons-dark-beneficios {
        margin-top: 1.15rem;
    }

    .img-cards {
        height: 440px;
        margin-top: 0rem;
        object-position: 0% 0;
    }

    .bg-handphone {
        height: 360px;
    }

}


/* Mobile */

@media screen and (max-width: 575px) {
    .container-title {
    text-align: center;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 15rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 1.5rem;
    }

    .container-white-phone {
        max-width: 700px;
    }
    
    .col-phone-white-left, .col-phone-white-right {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }

    .col-container-phone-white-right .col-phone-white-right-text:nth-child(2n){
        text-align: left;
    }

    .col-container-phone-white-left .col-phone-white-left-text:nth-child(odd){
        text-align: right;
    }

    .col-phone-white-left-text, .col-phone-white-right-text {
        margin-top: 0rem !important;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 4.8rem;
    }

    .row-container-title {
        justify-content: left;
    }

    .container-title {
        margin-left: 1rem;
    }

    .title-separator-wallet {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        width: 10rem;
    }

    .h1-text-wallet {
        font-size: 1.8rem;
    }

    .img-bitcoin {
        max-height: 1.2rem;
    }

    .img-phone-white {
        margin-bottom: 3rem;
    }

    .div-p-text-wallet {
        background-color: #000000;
        border-radius: 32px;
        height: 110px;
        width: 280px;
        margin: auto;
        transform: translateY(-80%);
        display: flex;
        align-items: center
    }

    .p-text-wallet {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-weight: 400;
        color: #ffffff;
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 1.2rem;
        text-align: center; 
    }

    .container-mastercard-bg {
        margin-top: -3rem;
    }

    .h1-text-mastercard {
        font-size: 1.6rem;
    }

    .img-circle-mastercard {
        height: 8rem;
    }

    .container-beneficios-list {
        padding-top: 0;
    }

    .img-cards {
        margin-top: -2rem;
        height: 540px;
    }

    .text-form-wallet {
      
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .bg-handphone {
        height: 300px;
    }

    .container-handphone {
        height: 18rem;
        transform: translateY(-30%);
    }

    .container-img-handphone {
        width: 28rem;
    }

    .img-hand {
        height: 46rem;
        margin-top: -8rem;
        object-position: 0% 0;
    }
    
}

@media screen and (max-width: 480px) {
    .container-title {
    text-align: center;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 15rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 1.5rem;
    }

    .row-container-title {
        justify-content: right;
    }
  
    .container-title {
        margin-left: 0;
        /* margin-right: 2rem; */
        margin-bottom: 6rem;
    }

    .div-p-text-wallet {
        width: 220px;
    }

    .p-text-wallet {
        font-size: 1rem;
        margin-left: 0rem;
        margin-right: 0rem;
    }
    

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 4rem;
    }
   
    .container-form-wallet {
        padding: 0;
        margin-bottom: 0rem;
    }

    .container-handphone h1 {
        font-size: 2.5rem;
    }

    .text-form-wallet {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .label-form-wallet {
        margin-top: 1rem;
        font-size: 1.2rem;
    }

    .input-form-wallet {
        font-size: 1rem;
    }

    .btn-form-wallet {
        margin-bottom: 1rem !important;
    }

    .h1-text-mastercard {
        font-size: 1.5rem;
    }

    .img-circle-mastercard {
        height: 8rem;
    }

    .container-icons-dark-beneficios {
       margin-top: 2.5rem;
    }

    .container-icons-dark-beneficios h2 {
        font-size: 1.2rem;
        margin-left: 0.3rem;
    }

    .container-beneficios-list {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .container-beneficios-list h1 {
        text-align: center;
    }

    .container-beneficios-list h3 {
        font-size: 1.2rem;
        margin-top: 0.5rem;
    }

    .last-h2-beneficios {
        font-size: 1.2rem;
    }

    .icons-dark-beneficios {
        width: 1.4rem;
        height: 1.4rem;
    }

    .img-cards {
        margin-top: -2rem;
        height: 480px;
    }

    .bg-handphone {
        height: 300px;
    }

    .col-div-container-phone {
        width: 350px;
    }

    .container-handphone {
        height: 18rem;
        transform: translateY(-30%);
    }

    .container-img-handphone {
        width: 24rem;
        
    }

    .img-hand {
        height: 40rem;
    }
}

@media screen and (max-width: 400px) {
    .container-title {
    text-align: center;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 15rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 1.5rem;
    }
    .div-p-text-wallet {
        width: 200px;
    }

    .p-text-wallet {
        font-size: 1rem;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 3.5rem;
    }

    .h1-text-mastercard {
        font-size: 1.2rem;
    }

    .img-circle-mastercard {
        height: 6rem;
    }

    .img-cards {
        margin-top: -2rem;
        height: 400px;
    }

    .text-form-wallet {
        font-size: 1.1rem;
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    .container-input-form-wallet {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .col-div-container-phone {
        width: 300px;
    }

    .col-div-container-phone h3 {
        font-size: 1.2rem;
    }

    .col-div-container-phone img {
        height: 1rem;
    }
}

@media screen and (max-width: 368px) {
    .container-title {
    text-align: center;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 15rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 1.5rem;
    }

    .div-p-text-wallet {
        width: 180px;
        height: 90px;
    }

    .p-text-wallet {
        padding-top: 0rem;
        padding-bottom: 0rem;
        font-size: 0.9rem;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 3.2rem;
    }

    .h1-text-mastercard {
        font-size: 1.1rem;
    }

    .img-cards {
        margin-top: -2rem;
        height: 368px;
    }

    .text-form-wallet {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    .col-div-container-phone {
        width: 280px;
    }

    .col-div-container-phone h3 {
        font-size: 1.1rem;
    }

    .col-div-container-phone img {
        height: 1rem;
    }
}


@media screen and (max-width: 320px) {
    .container-title {
    text-align: center;
    }

    .h1-text-wallet {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--textlight);
    }

    .h1-text-wallet-prox {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .h1-text-wallet-description {
    font-weight: bold;
    font-size: 1rem;
    color: var(--textlight);
    }

    .title-separator-wallet {
        border-bottom: 2px solid var(--textlight);
        margin-bottom: 1rem;
        margin-top: 1rem;
        width: 15rem;
        margin-left: 3rem;
    }

    .img-bitcoin {
        max-height: 1.5rem;
    }

    .title-separator-wallet {
        width: 8rem;
    }

    .div-p-text-wallet {
        width: 150px;
    }

    .p-text-wallet {
        font-size: 0.85rem;
    }

    .col-phone-white-left-text img, .col-phone-white-right-text img {
        height: 3rem;
    }

    .h1-text-mastercard {
        font-size: 1rem;
    }

    .img-cards {
        margin-top: -2rem;
        height: 320px;
    }

    .btn-form-wallet {
        font-size: 1rem;
        width: 12rem;
    }

    .row-banner-bg {
        height: 300px;    
    }
    
    .container-form-wallet {
        margin-bottom: 2rem;
    }

    .container-input-form-wallet {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .container-form-wallet h1 {
        font-size: 1.5rem;
    }

    .container-icons-dark-beneficios h2 {
        font-size: 1rem;
        margin-left: 0.3rem;
    }
     
    .container-beneficios-list h3 {
        font-size: 1rem;
    }

    .col-div-container-phone {
        width: auto;
    }

    .col-div-container-phone h3 {
        font-size: 0.8rem;
    }

    .col-div-container-phone img {
        height: 0.7rem;
    }
}
